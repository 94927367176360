@use 'sass:map';

$task-document: #2970ff;
$task-subscription: #b93815;
$task-form: #6129ff;
$task-training: #239888;

$colors: (
  'document': $task-document,
  'subscription': $task-subscription,
  'form': $task-form,
  'training': $task-training,
);

@each $name, $color in $colors {
  .text-#{$name} {
    color: #{$color} !important;
  }
  .bg-#{$name} {
    background-color: #{rgba($color, 0.8)};
  }
  .badge-#{$name} {
    background-color: #{rgba($color, 0.05)};
  }
}

$periodic-task-TODO: #374151;
$periodic-task-IN-PROGRESS: #004eeb;
$periodic-task-COMPLETED: #15803d;
$periodic-task-SUBMITTED: #ffa500;
$periodic-task-FAILED: #b91c1c;
$periodic-task-ARCHIVED: #374151;
$periodic-task-DRAFT: #374151;

$colors: (
  'todo': $periodic-task-TODO,
  'in_progress': $periodic-task-IN-PROGRESS,
  'active': $periodic-task-IN-PROGRESS,
  'completed': $periodic-task-COMPLETED,
  'passed': $periodic-task-COMPLETED,
  'submitted': $periodic-task-SUBMITTED,
  'failed': $periodic-task-FAILED,
  'archived': $periodic-task-ARCHIVED,
  'draft': $periodic-task-DRAFT,
);

@each $name, $color in $colors {
  .cat-badge-status-#{$name} {
    color: #{$color};
    background-color: #{mix(white, $color, 90%)};
    --mat-select-enabled-trigger-text-color: #{mix(
        black,
        $color,
        30%
      )} !important;
    --mat-select-enabled-arrow-color: #{rgba($color, 1)} !important;
    --mat-select-focused-arrow-color: #{rgba($color, 1)} !important;
    --mdc-outlined-text-field-focus-outline-color: transparent !important;
    --mdc-outlined-text-field-hover-outline-color: transparent !important;
    --mdc-outlined-text-field-outline-color: transparent !important;
    --mdc-list-list-item-container-color: white;
    --mat-option-selected-state-label-text-color: #{$color};
  }
}

$material-DOCUMENT: #004eeb;
$material-VIDEO: #374151;
$material-PRESENTATION: #15803d;
$material-FILE: #b54708;
$material-FORM: #b91c1c;

$material-colors: (
  'DOCUMENT': $material-DOCUMENT,
  'VIDEO': $material-VIDEO,
  'PRESENTATION': $material-PRESENTATION,
  'FILE': $material-FILE,
  'FORM': $material-FORM,
);

@each $name, $color in $material-colors {
  .cat-material-badge-#{$name} {
    background-color: #{rgba($color, 0.2)};
    color: #{$color} !important;
  }
}

$urgent-low: #004eeb;
$urgent-medium: #ffa500;
$urgent-high: #b42318;

$urgent-colors: (
  'low': $urgent-low,
  'medium': $urgent-medium,
  'high': $urgent-high,
);

@each $name, $color in $urgent-colors {
  .cat-urgent-badge-#{$name} {
    background-color: mix(white, $color, 95%) !important;
    color: $color !important;
  }
}
