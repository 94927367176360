:root {
  --fc-small-font-size: 1em;
  --fc-page-bg-color: transparent;
  --fc-neutral-bg-color: unset;
  --fc-neutral-text-color: #808080;
  --fc-border-color: #2e32381f;

  --fc-button-text-color: var(--base-contrast);
  --fc-button-bg-color: var(--white);
  --fc-button-border-color: var(--white);
  --fc-button-hover-bg-color: var(--white);
  --fc-button-hover-border-color: var(--white);
  --fc-button-active-bg-color: var(--accent);
  --fc-button-active-border-color: var(--accent);

  --fc-event-bg-color: unset;
  --fc-event-border-color: unset;
  --fc-event-text-color: var(--base-contrast);
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 0.5rem;
  --fc-event-resizer-dot-total-width: 0.5rem;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: unset;
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: #f5f7fe;
  --fc-now-indicator-color: var(--primary);
}

.fc-timeGridWeek-view,
.fc-dayGridMonth-view {
  background-color: var(--white);
}

.fc .fc-button-primary:focus {
  box-shadow: unset !important;
}

.fc .fc-button {
  padding: 0.6em 0.8em;
}

.fc-header-toolbar {
  border-bottom: 1px solid var(--link-water);
  padding: 1rem 0;
  .fc-toolbar-title {
    color: var(--accent);
    font-weight: 600;
  }
  .fc-today-button {
    border-color: transparent !important;
    color: var(--accent);
    &:hover {
      color: var(--accent);
    }
  }
  .fc-prev-button,
  .fc-next-button {
    color: var(--accent);
    &:hover {
      background: var(--white) !important;
      color: var(--accent) !important;
      border-color: var(--white) !important;
    }
  }

  .fc-button-group > .fc-button.fc-button-active {
    --fc-button-text-color: var(--white);
  }
}
.fc-list-view {
  border: unset;
  .fc-list-day {
    display: none;
  }
}

.fc-dayGridMonth-view,
.fc-timeGridWeek-view,
.fc-timeGridDay-view {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  table {
    border-top-style: hidden !important;
    border-left-style: hidden !important;
  }
  .fc-col-header-cell-cushion,
  .fc-list-day-cushion {
    padding: 2em 0.5em !important;
    font-weight: bolder;
  }
  .fc-list-day-text {
    width: 100%;
    text-align: center;
  }
  thead {
    border-bottom: 1px solid var(--gray-100);
    th {
      border: none !important;
    }
  }
}
