:root {
  --white: #fff;
  --black: #000;
  --gray-25: #fcfcfd;
  --gray-50: #f8fafc;
  --gray-100: #eef2f6;
  --gray-200: #e3e8ef;
  --gray-300: #cdd5df;
  --gray-400: #9aa4b2;
  --gray-500: #697586;
  --gray-600: #4b5565;
  --gray-700: #364152;
  --gray-800: #202939;
  --gray-900: #121926;

  --primary-25: #f5f8ff;
  --primary-50: #eff4ff;
  --primary-100: #d1e0ff;
  --primary-200: #b2ccff;
  --primary-300: #84adff;
  --primary-400: #528bff;
  --primary-500: #2970ff;
  --primary-600: #155eef;
  --primary-700: #004eeb;
  --primary-800: #0040c1;
  --primary-900: #00359e;

  --success-100: #d1fadf;
  --success-500: #12b76a;
  --success-700: #027a48;

  --primary-muted: #d1e0ff;
  --primary: var(--primary-600);
  --primary-contrast: var(--primary-700);

  --accent-muted: var(--primary-muted);
  --accent: var(--primary);
  --accent-contrast: var(--primary-contrast);

  --warn-muted: #fef0c7;
  --warn: #f79009;
  --warn-contrast: #b54708;

  --error-muted: #fee4e2;
  --error: #f04438;
  --error-contrast: #b42318;

  --bpmn-task: #0f54ae;
  --bpmn-start-event: #eda807;
  --bpmn-intermediate-throw-event: #56006e;
  --bpmn-end-event: #239888;
  --bpmn-exclusive-gateway: #9f2094;
  --bpmn-sub-process: #56006e;
  --bpmn-data-object-reference: #239888;
  --bpmn-data-store-reference: #56006e;
  --bpmn-group: #56006e;
  --bpmn-participant: #0f54ae;
}

$gray-25: var(--gray-25);
$gray-50: var(--gray-50);
$gray-100: var(--gray-100);
$gray-200: var(--gray-200);
$gray-300: var(--gray-300);
$gray-400: var(--gray-400);
$gray-500: var(--gray-500);
$gray-600: var(--gray-600);
$gray-700: var(--gray-700);
$gray-800: var(--gray-800);
$gray-900: var(--gray-900);

$primary-muted: var(--primary-muted);
$primary: var(--primary);
$primary-contrast: var(--primary-contrast);

$warn-muted: var(--warn-muted);
$warn: var(--warn);
$warn-contrast: var(--warn-contrast);

$error-muted: var(--error-muted);
$error: var(--error);
$error-contrast: var(--error-contrast);

$white: var(--white);
