@import '../variables/colors';
@import '../variables/typography';
@import './button';
@import './input';
@import './modal';
@import './tabs';
@import './stepper';
@import './select';
@import './menu';
@import './expansion-panel';
@import './switch';

// Overwrites
:root {
  --mat-sidenav-container-elevation-shadow: none;
}

.error-snackbar {
  --mdc-snackbar-supporting-text-color: var(--error);
  --mat-snack-bar-button-color: var(--error);
  --mdc-snackbar-container-color: var(--error-muted) !important;
  .mdc-snackbar__surface {
    border: 1px solid var(--error-muted);
  }
}
.success-snackbar {
  --mdc-snackbar-supporting-text-color: var(--success-500);
  --mat-snack-bar-button-color: var(--success-500);
  --mdc-snackbar-container-color: var(--success-100) !important;
  .mdc-snackbar__surface {
    border: 1px solid var(--success-100);
  }
}

.bg-loading {
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    opacity: 0.4;
    z-index: 9999;
  }
}
