:root {
  --mdc-switch-track-height: 20px;
  --mdc-switch-track-shape: 12px;
  --mdc-switch-unselected-track-color: var(--gray-100);
  --mdc-switch-unselected-focus-track-color: var(--gray-100);
  --mdc-switch-unselected-hover-track-color: var(--gray-100);
  --mdc-switch-unselected-pressed-track-color: var(--gray-100);
  --mdc-switch-selected-track-color: var(--primary-700);
  --mdc-switch-selected-focus-track-color: var(--primary-700);
  --mdc-switch-selected-hover-track-color: var(--primary-700);
  --mdc-switch-selected-pressed-track-color: var(--primary-700);
  --mdc-switch-unselected-handle-color: var(--white);
  --mdc-switch-unselected-focus-handle-color: var(--white);
  --mdc-switch-unselected-hover-handle-color: var(--white);
  --mdc-switch-selected-handle-color: var(--white);
  --mdc-switch-selected-focus-handle-color: var(--white);
  --mdc-switch-selected-hover-handle-color: var(--white);
  --mdc-switch-unselected-pressed-handle-color: var(--white);
  --mat-switch-with-icon-handle-size: 16px;
  --mdc-switch-state-layer-size: 32px;
}
