@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

@import url(./theme/_theme.scss);
@import url(./theme/_overwrite.scss);

@import url(./_mixins.scss);
@import url(./components/_calendar.scss);
@import url(./components/_help-center.scss);
@import url(./components/_bpmn.scss);
@import url(./components/_chat.scss);

html,
body {
  height: 100vh;
}

@layer base {
  img {
    @apply inline-block;
  }
}

@layer components {
  .cat-ai-link {
    @apply text-primary-700;
  }
  .cat-ai-textarea {
    @apply rounded-md  border border-gray-100 p-3;
  }
  .cat-ai-label {
    @apply block mb-1 text-sm font-medium;
  }

  .cat-ai-shadow {
    @apply shadow-main;
  }

  .cat-ai-table-header-cell {
    @apply text-left bg-gray-50 py-3 px-6 uppercase text-gray-600 font-medium text-xs whitespace-nowrap border-y border-gray-100;
  }

  .cat-ai-table-cell {
    @apply h-[81px] py-4 px-6 whitespace-nowrap  text-gray-600 font-normal overflow-hidden text-ellipsis max-w-48;
  }

  .cat-ai-table-body-row {
    @apply border-y border-gray-100;
  }

  .cat-ai-chip {
    &-blue {
      @apply bg-blue-200 text-blue-700 border-blue-700;
    }

    &-green {
      @apply bg-green-200 text-green-700 border-green-700;
    }

    &-purple {
      @apply bg-purple-200 text-purple-700 border-purple-700;
    }

    &-violet {
      @apply bg-violet-200 text-violet-700 border-violet-700;
    }

    &-red {
      @apply bg-red-200 text-red-700 border-red-700;
    }

    &-gray {
      @apply bg-gray-200 text-gray-700 border-gray-700;
    }

    &-orange {
      @apply bg-warn-muted text-warn-contrast border-warn-contrast;
    }
  }

  .cat-ai-radio {
    &-label {
      @apply px-5 py-3.5 flex items-center justify-center gap-2.5 transition-colors bg-blue-100 rounded-full border border-blue-200 peer-checked:border-blue-500 peer-focus:border-blue-500 cursor-pointer;
    }
  }

  .cat-canban-grid {
    @apply grid grid-cols-3 gap-3 auto-rows-fr grid-flow-dense auto-cols-fr;
  }

  .cat-canban-panel-item {
    @apply bg-none text-white;
    .task-panel-badge {
      @apply bg-white;
    }
  }

  label.cat-ai-body-part-btn {
    @apply border border-primary-500 bg-white text-primary-500 p-2 cursor-pointer peer-checked:bg-primary-500 peer-checked:text-white #{!important};
  }
  .cat-calendar-filters > formly-field > formly-group {
    @apply grid grid-cols-5 gap-2;
    formly-field:last-of-type {
      grid-column: 1 / 3;
    }
  }

  .cat-ai-formly-form {
    & > formly-field > formly-group {
      @apply flex flex-col gap-4;
    }
  }

  .cat-ai-document-card {
    @apply block bg-white p-3 rounded-m;
    box-shadow:
      0 5px 15px 0 rgba(0, 0, 0, 0.03),
      0px -2px 15px 0px rgba(0, 0, 0, 0.05);

    &__header {
      @apply flex items-center gap-2 border-b pb-2.5 mb-2.5;
    }

    &_active {
      @apply bg-primary-600 text-white;

      & .cat-ai-document-card__divider,
      & .cat-ai-document-card__time {
        @apply text-white;
      }

      & .cat-ai-document-card__header {
        @apply border-[rgba(239,240,243,0.1)];
      }
    }
  }

  .cat-ai-document-status {
    @apply text-xs text-gray-700 inline-flex items-end px-2 py-1 capitalize rounded-large;

    &_in-progress {
      @apply text-orange-700 bg-orange-100;
    }

    &_draft {
      @apply text-gray-700 bg-gray-100;
    }

    &_draft-inverted {
      @apply bg-gray-50 text-gray-700;
    }

    &_success {
      @apply text-green-700 bg-green-100;
    }

    &_fail {
      @apply text-red-700 bg-red-100;
    }
  }

  .cat-ai-message-status {
    @apply py-3 px-4 rounded-[8px] hyphens-auto;
    border-left-width: 1px;
    word-break: break-word;
    &_reply {
      border-color: #697485;
      background-color: #e5ebf5;
    }
    &_approved {
      @apply text-success-700 border-l-success-700 bg-success-100;
    }
    &_rejected {
      @apply text-error-contrast border-l-error-contrast bg-error-muted;
    }

    &__header {
      @apply text-sm/[14px] mb-2 font-bold;
      &_rejected {
        @apply text-error-contrast;
      }
      &_approved {
        @apply text-success-700;
      }
    }
  }

  .cat-ai-document-group-date {
    @apply flex items-center gap-4 text-gray-400 px-4;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--gray-100);
    }
  }
}

.gradient-line {
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, var(--gray-100), transparent) 1;
}

.basic-line {
  border-bottom: 1px solid;
  border-color: var(--gray-100);
}

$page-h: 297mm;
$page-w: 210mm;
$page-my: 10mm;
$page-mx: 16mm;
.cat-ai-pages {
  width: $page-w;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }

  &_page-preview {
    visibility: hidden;
    width: $page-w;
    height: $page-h;
  }

  &_content-preview {
    visibility: hidden;
    font-size: 14px;
    line-height: 20px;
    width: calc($page-w - $page-mx - $page-mx);
    white-space: pre-line;
  }
  &_content {
    padding: 0 $page-mx;
    position: relative;
    ul {
      list-style-type: disc;
      list-style-position: inside;
    }
    ol {
      list-style-type: decimal;
      list-style-position: inside;
    }
  }
  &_header {
    padding: $page-my $page-mx 0 $page-mx;
    z-index: 0;
  }
  .divider {
    width: 1px;
    float: left;
  }

  &_footer {
    padding: 0 $page-mx $page-my $page-mx;
    clip-path: inset(1px -10px -10px -10px);
  }
  &_constant-blocks {
    .cat-ai-pages_header,
    .cat-ai-pages_footer {
      margin: 0;
    }
  }
}

.cat-canban-grid {
  :nth-child(2) {
    grid-area: 1/2/1/4;
  }
  :nth-child(3) {
    grid-area: span 2 / 1;
  }
  :nth-child(5) {
    grid-area: span 2;
  }
  :last-child {
    grid-column: span 2/4;
  }
}

.periodic-tasks-list {
  .cat-ai-table-cell,
  .cat-ai-table-header-cell {
    padding-left: 12px;

    &.checkbox {
      width: 1%;
      padding-right: 0;
    }
  }
}

.periodic-task-materials {
  .cat-ai-table-header-cell {
    border-top: none;
  }
}

.h-inherit {
  height: inherit;
}

.sceleton {
  &::after {
    @apply absolute right-0 left-0 bottom-0 bg-gray-100 rounded-xs;
    content: '';
    top: -4px;
    z-index: 9999;
  }
  @apply animate-pulse relative;
}

highcharts-chart {
  max-width: 220px;
  height: 142px;
  min-height: 142px;
  display: block;
}

.highcharts-wrap {
  max-width: 220px;
  height: 142px;
  min-height: 142px;
  min-width: 220px;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  --mdc-snackbar-container-color: white;
  --mdc-snackbar-container-shape: 12px !important;
  @apply shadow-lg #{!important};
}

.page-b5 {
  width: 11in;
  height: 8.5in;
}

.document {
  .cat-ai-table-cell {
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: unset;
  }
}

.answered {
  @apply bg-primary-25 border-primary-600 text-primary-600;
}

.double-shadow {
  box-shadow:
    0px -32px 64px -12px #1018280d,
    0px 32px 64px -12px #10182824;
}
.control-modal-header {
  @apply block bg-white p-3 rounded-m;
  box-shadow:
    0 5px 15px 0 rgba(0, 0, 0, 0.03),
    0px -2px 15px 0px rgba(0, 0, 0, 0.05);

  &__header {
    @apply flex items-center gap-2 border-b pb-2.5 mb-2.5;
  }

  &_active {
    @apply bg-primary-600 text-white;

    & .cat-ai-document-card__divider,
    & .cat-ai-document-card__time {
      @apply text-white;
    }

    & .cat-ai-document-card__header {
      @apply border-[rgba(239,240,243,0.1)];
    }
  }
}

@layer utilities {
  .no-scroll-bar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.cat-ai-editor-container {
  .header-selector {
    width: 50%;
    order: 2;
    background: white;
  }
  &.chat-collapsed .ql-container {
    margin: 2rem auto;
  }
}

.ql-container {
  order: 2;
  width: 100%;
  max-width: $page-w;
  margin: 2rem;
  padding: $page-mx $page-my;
  min-height: $page-h;
  background: white;
  border: none !important;
  .ql-editor {
    padding: unset;
    strong {
      font-weight: bold;
    }
  }
  .ql-editor.ql-blank::before {
    left: $page-my;
  }

  @apply shadow-lg;
}

.ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  border-width: 0 0 1px 0 !important;
  border-color: var(--gray-200) !important;
  padding: 12px 16px !important;

  width: 50%;
  background: white;
  order: 1;
  .ql-stroke {
    stroke: var(--gray-400);
  }
  .ql-formats {
    @apply text-gray-400;
    display: flex;
    align-items: center;
    gap: 6px;
    margin: unset;
    button {
      padding: unset !important;
      display: inline-flex !important;
      width: unset;
      height: unset;
    }
  }
}
