:root {
  --mat-stepper-header-height: unset;
  --mat-stepper-header-hover-state-layer-color: unset;
  --mat-stepper-header-focus-state-layer-color: unset;
  --mat-stepper-line-color: var(--gray-200);
}
.mat-horizontal-stepper-header,
.mat-horizontal-content-container {
  padding: 0 !important;
}

.mat-stepper-horizontal-line {
  margin: 0 8px !important;
  max-width: 140px;
}
