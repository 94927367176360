// Overwrites
:root {
  --mdc-dialog-container-shape: 12px;
  --mat-dialog-with-actions-content-padding: 24px 24px 0;
  --mat-dialog-actions-padding: 24px;
  --modal-headline-padding: 24px;
  --mat-dialog-headline-padding: 0 var(--modal-headline-padding)
    var(--modal-headline-padding);
  --mat-dialog-container-max-width: 400px;
  --mdc-dialog-supporting-text-line-height: 18px;
  --mdc-dialog-supporting-text-size: 14px;
}

.confirm-modal {
  position: relative;

  --mat-dialog-with-actions-content-padding: 0 var(--modal-headline-padding);

  &__heading {
    padding: var(--modal-headline-padding) 54px 8px
      var(--modal-headline-padding);
  }
}

.document-preview {
  .mdc-dialog__surface {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .mat-mdc-dialog-content {
    max-height: 100vh;
  }
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    overflow: hidden;
  }
}

.cdk-overlay-pane {
  min-width: min-content;
}

.preview-task {
  .mat-mdc-dialog-content {
    max-height: unset;
  }
}
