$font-weight-regular: 335;
$font-weight-medium: 480;
$font-weight-semibold: 590;
$font-weight-bold: 690;

:root {
  --font-weight-regular: #{$font-weight-regular};
  --font-weight-medium: #{$font-weight-medium};
  --font-weight-semibold: #{$font-weight-semibold};
  --font-weight-bold: #{$font-weight-bold};
}
