.cat-ai-chat-bubble {
  background: var(--bubble-chat-color);
  display: flex;
  flex-direction: column;
  padding: 0.7rem 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  &_me {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 1rem;
  }
}
