@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

@import url(./theme/_theme.scss);
@import url(./theme/_overwrite.scss);

@import url(./_mixins.scss);
@import url(./components/_calendar.scss);
@import url(./components/_help-center.scss);
@import url(./components/_bpmn.scss);

html,
body {
  height: 100vh;
}

@layer base {
  img {
    @apply inline-block;
  }
}

@layer components {
  .cat-ai-link {
    @apply text-primary-700;
  }
  .cat-ai-textarea {
    @apply rounded-md  border border-gray-100 p-3;
  }
  .cat-ai-label {
    @apply block mb-1 text-sm font-medium;
  }

  .cat-ai-shadow {
    @apply shadow-main;
  }

  .cat-ai-table-header-cell {
    @apply text-left bg-gray-50 py-3 px-6 uppercase text-gray-600 font-medium text-xs whitespace-nowrap border-y border-gray-100;
  }

  .cat-ai-table-cell {
    @apply h-[81px] py-4 px-6 whitespace-nowrap  text-gray-600 font-normal overflow-hidden text-ellipsis max-w-48;
  }

  .cat-ai-table-body-row {
    @apply border-y border-gray-100;
  }

  .cat-ai-chip {
    &-blue {
      @apply bg-blue-200 text-blue-700 border-blue-700;
    }

    &-green {
      @apply bg-green-200 text-green-700 border-green-700;
    }

    &-purple {
      @apply bg-purple-200 text-purple-700 border-purple-700;
    }

    &-violet {
      @apply bg-violet-200 text-violet-700 border-violet-700;
    }

    &-red {
      @apply bg-red-200 text-red-700 border-red-700;
    }

    &-gray {
      @apply bg-gray-200 text-gray-700 border-gray-700;
    }

    &-orange {
      @apply bg-warn-muted text-warn-contrast border-warn-contrast;
    }
  }

  .cat-ai-radio {
    &-label {
      @apply px-5 py-3.5 flex items-center justify-center gap-2.5 transition-colors bg-blue-100 rounded-full border border-blue-200 peer-checked:border-blue-500 peer-focus:border-blue-500 cursor-pointer;
    }
  }

  .cat-canban-grid {
    @apply grid grid-cols-3 gap-3 auto-rows-fr grid-flow-dense auto-cols-fr;
  }

  .cat-canban-panel-item {
    @apply bg-none text-white;
    .task-panel-badge {
      @apply bg-white;
    }
  }

  label.cat-ai-body-part-btn {
    @apply border border-primary-500 bg-white text-primary-500 p-2 cursor-pointer peer-checked:bg-primary-500 peer-checked:text-white #{!important};
  }
  .cat-calendar-filters > formly-field > formly-group {
    @apply grid grid-cols-5 gap-2;
    formly-field:last-of-type {
      grid-column: 1 / 3;
    }
  }

  .cat-ai-formly-form {
    & > formly-field > formly-group {
      @apply flex flex-col gap-4;
    }
  }
}

.gradient-line {
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, var(--gray-100), transparent) 1;
}

.basic-line {
  border-bottom: 1px solid;
  border-color: var(--gray-100);
}

$page-h: 297mm;
$page-w: 210mm;
$page-my: 10mm;
$page-mx: 16mm;
.cat-ai-pages {
  width: $page-w;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }

  &_page-preview {
    visibility: hidden;
    width: $page-w;
    height: $page-h;
  }

  &_content-preview {
    visibility: hidden;
    font-size: 14px;
    line-height: 20px;
    width: calc($page-w - $page-mx - $page-mx);
    white-space: pre-line;
  }
  &_content {
    padding: 0 $page-mx;
  }
  &_header {
    padding: $page-my $page-mx 0 $page-mx;
  }
  &_header::before {
    content: '';
    position: absolute;
    display: block;
    background: white;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: $page-h;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  }

  &_footer {
    padding: 0 $page-mx $page-my $page-mx;
  }
  // TODO: restore when code smell in document-snapshot will be removed
  // &_constant-blocks {
  //   .cat-ai-pages_header,
  //   .cat-ai-pages_footer {
  //     padding-top: 0;
  //     padding-bottom: 0;
  //     margin: 0;
  //   }
  // }
}

.cat-canban-grid {
  :nth-child(2) {
    grid-area: 1/2/1/4;
  }
  :nth-child(3) {
    grid-area: span 2 / 1;
  }
  :nth-child(5) {
    grid-area: span 2;
  }
  :last-child {
    grid-column: span 2/4;
  }
}

.periodic-tasks-list {
  .cat-ai-table-cell,
  .cat-ai-table-header-cell {
    padding-left: 12px;

    &.checkbox {
      width: 1%;
      padding-right: 0;
    }
  }
}

.periodic-task-materials {
  .cat-ai-table-header-cell {
    border-top: none;
  }
}

.h-inherit {
  height: inherit;
}

.sceleton {
  &::after {
    @apply absolute right-0 left-0 bottom-0 bg-gray-100 rounded-xs;
    content: '';
    top: -4px;
    z-index: 9999;
  }
  @apply animate-pulse relative;
}

highcharts-chart {
  max-width: 220px;
  height: 142px;
  min-height: 142px;
  display: block;
}

.highcharts-wrap {
  max-width: 220px;
  height: 142px;
  min-height: 142px;
  min-width: 220px;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  --mdc-snackbar-container-color: white;
  --mdc-snackbar-container-shape: 12px !important;
  @apply shadow-lg #{!important};
}

.page-b5 {
  width: 11in;
  height: 8.5in;
}

.document {
  .cat-ai-table-cell {
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: unset;
  }
}

.answered {
  @apply bg-primary-25 border-primary-600 text-primary-600;
}

.double-shadow {
  box-shadow:
    0px -32px 64px -12px #1018280d,
    0px 32px 64px -12px #10182824;
}
