// Overwrites
:root {
  --mdc-outlined-text-field-container-shape: 8px;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-vertical-padding: 8px;
  --mdc-form-field-label-text-line-height: 21px;
  --mdc-icon-button-state-layer-size: 40px;
  --mdc-outlined-text-field-outline-color: var(--gray-300);
  --mdc-outlined-text-field-hover-outline-color: var(--primary-700);
  --mdc-outlined-text-field-input-text-placeholder-color: var(--gray-500);
  --mat-select-placeholder-text-color: var(--gray-500);
  --mdc-outlined-text-field-label-text-color: var(--gray-500);
  --mdc-checkbox-state-layer-size: 18px;
}

textarea {
  resize: none !important;
  &:focus {
    outline: none;
  }
}

.cat-file-input {
  label {
    border-radius: var(--mdc-outlined-text-field-container-shape);
    border: 1px solid var(--mdc-outlined-text-field-outline-color);
    color: var(--mdc-outlined-text-field-input-text-placeholder-color);
  }

  &_error {
    & label {
      border: 1px solid var(--mdc-outlined-text-field-error-outline-color);
    }
  }
}

// FORM-FIELDS
.mat-mdc-form-field .mat-mdc-text-field-wrapper {
  background: var(--white);
}
.mat-mdc-select-panel {
  background-color: var(--white) !important;
}
.mat-ripple,
.mdc-button__ripple,
.mat-mdc-form-field-focus-overlay {
  display: none !important;
}

.mat-mdc-form-field-type-mat-input.h-full {
  .mat-mdc-text-field-wrapper.mdc-text-field {
    min-height: 100%;
  }
  .mat-mdc-form-field-infix,
  textarea {
    height: 100% !important;
  }
}

.mat-mdc-form-field-type-mat-input:not(:has(textarea)),
.mat-mdc-form-field-type-mat-select {
  border-radius: var(--mdc-outlined-text-field-container-shape);

  &.mat-form-field-disabled {
    background-color: unset;
  }
}

.mat-select-panel ng-animating {
  visibility: hidden;
}
.mat-mdc-form-field-has-icon-suffix,
.mat-mdc-form-field-has-icon-prefix {
  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    color: var(--mat-select-enabled-arrow-color) !important;
  }

  &.mat-form-field-disabled {
    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix {
      color: var(--mat-select-disabled-arrow-color) !important;
    }
  }
  &.mat-focused {
    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix {
      color: var(--mat-select-focused-arrow-color) !important;
    }
  }
}

.mat-mdc-form-field-type-mat-select.mat-form-field-invalid,
.mat-mdc-form-field-type-mat-input.mat-form-field-invalid {
  position: relative;
  .mat-mdc-form-field-subscript-wrapper {
    position: absolute;
    bottom: calc((var(--mat-form-field-subscript-text-size) + 4px) * -1);
  }
}

.mat-mdc-form-field-flex {
  height: 100% !important;
  align-items: center !important;

  .mdc-floating-label {
    vertical-align: middle;
  }

  .mat-mdc-select-arrow {
    svg {
      display: none;
    }
  }
}

.mat-mdc-form-field-icon-suffix .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  --mdc-icon-button-state-layer-size: 40px;
}

.mat-mdc-form-field-icon-suffix {
  padding: 0 4px !important;
}

.mdc-text-field--filled {
  border-bottom-right-radius: var(
    --mdc-filled-text-field-container-shape
  ) !important;
  border-bottom-left-radius: var(
    --mdc-filled-text-field-container-shape
  ) !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field {
  border-radius: 10px;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}
.mat-mdc-form-field-has-icon-suffix {
  .mdc-text-field--outlined {
    padding-left: 0.75rem !important;
  }
}
.mat-mdc-form-field-icon-prefix {
  padding: 0 !important;
}
.mat-mdc-form-field-type-mat-input:not(.mat-mdc-form-field-has-icon-suffix):not(
    .mat-mdc-form-field-has-icon-prefix
  ) {
  .mdc-text-field--outlined {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
}

// RADIO-GROUP

.mat-mdc-radio-group.cat-radio {
  .mat-mdc-radio-button:not(:first-of-type) {
    margin-left: 0.375rem !important;
  }
  .mat-mdc-radio-button:last-of-type {
    margin-right: 0.375rem !important;
  }
  .mdc-label {
    padding: 0;
  }
}

.cat-radio-vertical.mat-mdc-radio-group {
  .mdc-radio {
    padding-left: 0 !important;
  }
}

.cat-input {
  border: var(--mdc-outlined-text-field-outline-width) solid;
  border-color: transparent;
  border-radius: var(--mdc-outlined-text-field-container-shape);
}

.cat-invalid {
  border-color: var(--mdc-outlined-text-field-error-outline-color);
}

.cat-badge-select {
  --mdc-outlined-text-field-container-shape: 16px;
  --mat-form-field-container-vertical-padding: 6px;
  --mat-form-field-container-height: 0px !important;
  --mat-select-trigger-text-line-height: 14px;
  --mat-option-label-text-line-height: 16px;
  --mat-form-field-container-text-line-height: 14px;
  --mat-select-trigger-text-weight: 550;
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0 2px 0 0 !important;
  }
  .mat-mdc-form-field-icon-suffix {
    padding: 0 !important;
  }
  .mat-mdc-option {
    min-height: unset !important;
    padding: 4px 4px 4px 12px !important;
  }
  .mat-mdc-select-arrow-wrapper {
    display: none;
  }
  .mat-mdc-form-field-infix {
    width: min-content;
  }
  &.mat-mdc-select-panel {
    padding: 0 !important;
  }
  .mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: calc(var(--mat-form-field-container-vertical-padding) + 1px);
  }
}
