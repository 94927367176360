:root {
  --mat-filled-button-horizontal-padding: 12px;
  --mat-text-button-with-icon-horizontal-padding: 15px;
  --mat-mdc-button-persistent-ripple-color: none;
  --mdc-filled-button-label-text-color: unset;
  --mat-text-button-icon-spacing: 8px;
  --mdc-text-button-container-shape: 0.5rem;
  --mdc-filled-button-container-shape: 0.5rem;
  --mat-filled-button-icon-width: 21px;
  --mat-filled-button-icon-height: unset default;
  --mat-filled-button-icon-offset: 0px;
  --mdc-filled-button-container-height: unset;
  --mat-standard-button-toggle-shape: 8px;
  --mat-standard-button-toggle-height: 38px;
  --mat-standard-button-toggle-divider-color: var(--gray-300);
  --mat-standard-button-toggle-selected-state-background-color: var(--gray-300);
  --mat-standard-button-toggle-text-color: var(--gray-500);
  --mat-standard-button-toggle-selected-state-text-color: var(--gray-500);
}
.mat-basic {
  --mdc-filled-button-container-color: unset;
  border: 1px solid var(--gray-300) !important;
  --mdc-filled-button-label-text-color: var(--gray-600);
  --mdc-filled-button-disabled-container-color: transparent;
  &.mat-mdc-button-disabled {
    border: 1px solid var(--gray-200) !important;
  }
}

.mat-accent {
  --mdc-filled-button-container-color: var(--gray-600);
  --mdc-filled-button-label-text-color: var(--white) !important;
  --mdc-filled-button-disabled-label-text-color: var(--white);
  --mdc-filled-button-disabled-container-color: var(--primary-100);
  --mat-fab-small-foreground-color: var(--white) !important;
}

.mat-light {
  --mdc-filled-button-container-color: transparent;
  --mdc-filled-button-label-text-color: var(--gray-400);
}

.mat-clear {
  --mdc-filled-button-container-color: transparent;
  --mdc-filled-button-label-text-color: var(--gray-700);
  --mdc-filled-button-disabled-label-text-color: var(--gray-700);
  --mdc-filled-button-disabled-container-color: transparent;
}

.mat-warn {
  --mdc-filled-button-disabled-label-text-color: var(--white);
  --mdc-filled-button-disabled-container-color: var(--error-muted);
}

.mat-fancy {
  &:disabled {
    background: linear-gradient(
      94.48deg,
      rgba(21, 94, 239, 0.2) -1.7%,
      rgba(255, 59, 141, 0.2) 103.77%
    ) !important;
    .mat-icon,
    .mdc-button__label {
      opacity: 0.5;
    }
  }

  background: linear-gradient(
    94.48deg,
    #155eef -1.7%,
    #ff3b8d 103.77%
  ) !important;

  --mdc-filled-button-label-text-color: var(--white) !important;
  --mdc-filled-button-disabled-label-text-color: var(--white);
  --mdc-filled-button-disabled-container-color: var(--primary-100);
}

.mdc-button {
  min-width: unset !important;
  &[mat-flat-button] {
    .mdc-button__label {
      padding: 0.75rem 0;
      line-height: 0.75rem;
      white-space: nowrap;
    }

    &.outlined {
      background-color: transparent;
      border: 1px solid $error-muted;
      color: var(--mdc-filled-button-container-color);
    }
  }

  &[mat-button] {
    & .mat-mdc-button-touch-target {
      height: 100%;
    }
  }
}

.mat-secondary-active,
.mat-secondary {
  .mat-icon {
    width: var(--mat-filled-button-icon-width) !important;
    height: var(--mat-filled-button-icon-height) !important;
  }
  &.submenu {
    padding-left: calc(
      var(--mat-filled-button-horizontal-padding) +
        var(--mat-filled-button-icon-width) +
        var(--mat-filled-button-icon-spacing)
    ) !important;
    --mdc-filled-button-label-text-weight: $font-weight-regular !important;
    .mdc-button__label {
      padding: 0.5rem 0;
    }
  }
  mat-icon[iconpositionend] {
    margin-left: auto !important;
  }
}
.mat-secondary-active {
  &.submenu {
    background: #ffffff !important;
  }
}
.mat-secondary-active {
  &.menu-item {
    background: #ffffff !important;
  }
  --mdc-filled-button-label-text-color: var(--accent);
  --mdc-filled-button-container-color: var(--primary-50);
  .mat-icon {
    color: var(--accent);
  }
}
.mat-secondary {
  background: transparent !important;
  --mdc-filled-button-label-text-color: var(--gray-700);
  .mat-icon {
    color: var(--gray-500);
  }
}

.mdc-notched-outline__notch {
  border-right: none;
}

.mat-mdc-button-touch-target {
  display: none !important;
}

.cat-toggle_wrap {
  border-style: solid;
  border-color: var(--mdc-outlined-text-field-outline-color) !important;
  border-radius: 0;
  border-width: 1px;
  border-top-left-radius: var(--mdc-outlined-text-field-container-shape);
  border-bottom-left-radius: var(--mdc-outlined-text-field-container-shape);
  border-top-right-radius: var(--mdc-outlined-text-field-container-shape);
  border-bottom-right-radius: var(--mdc-outlined-text-field-container-shape);
}
.cat-toggle.mat-mdc-icon-button {
  &.mat-clear {
    color: var(--mat-select-enabled-arrow-color);
  }
  &:first-child {
    &::after {
      content: '';
      width: 1px;
      background: var(--mdc-outlined-text-field-outline-color);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: var(--mat-form-field-container-vertical-padding);
  height: calc(var(--mat-filled-button-icon-width) * 2 - 2px);
  width: calc(var(--mat-filled-button-icon-width) * 2);
}

.mat-mdc-fab {
  --mdc-fab-container-shape: 10px;
  --mat-fab-foreground-color: var(--mdc-filled-button-label-text-color);

  box-shadow: none !important;
  background-color: var(--mdc-filled-button-container-color);
  width: var(--mdc-icon-button-state-layer-size) !important;
  height: var(--mdc-icon-button-state-layer-size) !important;
}

.mat-mdc-mini-fab {
  --mdc-icon-button-state-layer-size: 36px;
  &.square {
    --mdc-fab-small-container-shape: 8px;
  }
  &.cat-ai-chat-menu-btn {
    --mdc-icon-button-state-layer-size: 40px;
  }
  &:not(.mdc-fab--extended) {
    border-radius: 10px !important;
  }
  --mat-fab-small-foreground-color: var(--mdc-filled-button-label-text-color);
  box-shadow: none !important;
  background-color: var(--mdc-filled-button-container-color);
  width: var(--mdc-icon-button-state-layer-size) !important;
  height: var(--mdc-icon-button-state-layer-size) !important;

  &.square {
    --mdc-fab-small-container-shape: 8px;
  }
  &.outlined {
    background-color: transparent;
    border: 1px solid $error-muted;
    color: var(--mdc-fab-small-container-color);
  }
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 7px !important;
}

.mat-loading .mdc-button__label {
  opacity: 0.5;
}

.mat-loading .mat-mdc-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  z-index: 1;
}
.active-bg {
  background-color: #ffffff !important;
  color: var(--color-secondary-active) !important;
}
