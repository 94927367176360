.mat-expansion {
  &-indicator::after {
    border-color: var(--black) !important;
  }

  &-panel {
    box-shadow: 0px 1px 2px 0px var(--shadow-main) !important;
    border-radius: 8px !important;
    border: 1px solid var(--gray-200);

    &-header {
      background-color: var(--gray-25);
      height: auto !important;
      padding: 16px !important;
    }

    &-body {
      padding: 0 !important;
    }
  }
}
