@import '../variables/typography';

@font-face {
  font-family: 'MozaicGEO';
  src: url('../../assets/webFonts/MozaicGEORegular/MozaicGEORegular.woff')
    format('woff');
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: 'MozaicGEO';
  src: url('../../assets/webFonts/MozaicGEOMedium/MozaicGEOMedium.woff')
    format('woff');
  font-weight: $font-weight-medium;
  font-style: normal;
}

@font-face {
  font-family: 'MozaicGEO';
  src: url('../../assets/webFonts/MozaicGEOSemiBold/MozaicGEOSemiBold.woff')
    format('woff');
  font-weight: $font-weight-semibold;
  font-style: normal;
}

@font-face {
  font-family: 'MozaicGEO';
  src: url('../../assets/webFonts/MozaicGEOBold/MozaicGEOBold.woff')
    format('woff');
  font-weight: $font-weight-bold;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: unset !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--gray-800);
}

a {
  font-weight: $font-weight-semibold;
}

h4 {
  font: $font-weight-semibold 18px / 16px MozaicGEO !important ;
}

h5 {
  font: $font-weight-medium 14px / 21px MozaicGEO !important ;
}
