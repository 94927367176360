html {
  .mat-mdc-fab {
    &.help-center-btn {
      --mdc-fab-container-color: #004eeb1a;
      --mat-fab-foreground-color: var(--primary);
      --mdc-fab-container-shape: 50%;

      &:hover {
        --mdc-fab-container-color: var(--primary);
        --mat-fab-foreground-color: var(--white);
      }
    }
    &.help-center-delete-btn {
      --mdc-fab-container-color: rgba(240, 68, 56, 0.08);
      --mat-fab-foreground-color: var(--error);
      --mdc-fab-container-shape: 50%;

      &:hover {
        --mdc-fab-container-color: var(--error);
        --mat-fab-foreground-color: var(--white);
      }
    }
  }
}
