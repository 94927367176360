@import '../variables/typography';

:root {
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-color: var(--gray-700);
  --mat-option-label-text-line-height: 12px;
  --mat-option-label-text-weight: $font-weight-medium;
  --mat-option-hover-state-layer-color: var(--gray-100);
  --mat-option-focus-state-layer-color: var(--gray-50);
}

div.mat-mdc-select-panel {
  padding: 4px !important;
  border-radius: 11px !important;
  border: 1px solid var(--gray-200);
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mat-mdc-option {
  padding: 11px 14px !important;
  min-height: auto !important;
  transition: background-color 100ms ease-in-out !important;
  border-radius: 6px;
  overflow: visible !important;

  & .mat-pseudo-checkbox-full {
    margin-right: 8px !important;
  }
}
