.cat-ai {
  --mdc-secondary-navigation-tab-container-height: 2.25rem;
  &.mdc-tab {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  &:not(.mdc-tab--active) {
    border-bottom: 1px solid var(--gray-200);
  }
}

.mat-mdc-tab-group {
  gap: 1.5rem;
}
.mat-mdc-tab-labels::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-bottom: 1px solid var(--gray-200);
}

.periodic-task-materials {
  &.mat-mdc-tab-group {
    gap: 14px;
  }
  .mat-mdc-tab-header {
    padding: 0 1.5rem;
  }
}

.header-less-tabs.mat-mdc-tab-group .mat-mdc-tab-header {
  display: none !important;
}

.header-less-tabs .mat-horizontal-stepper-header-container {
  display: none !important;
}

.allow-tabs-grow.mat-mdc-tab-group {
  .mat-mdc-tab-body-wrapper {
    height: 100%;
  }
}
