:root {
  --mat-menu-container-shape: 10px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: var(
    --mat-menu-item-with-icon-trailing-spacing
  );
}

.mat-mdc-menu-content {
  padding: calc(
    var(--mat-menu-item-with-icon-trailing-spacing) - 0.5rem
  ) !important;

  .mat-mdc-menu-item {
    padding: calc(var(--mat-menu-item-with-icon-trailing-spacing) - 4px);
    border-radius: 10px;
    min-height: unset;
  }
}
