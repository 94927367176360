.djs-direct-editing-content {
  font-family: inherit !important;
  font-size: 12px !important;
}

.bpmn-icon-start-event-none::before {
  color: var(--bpmn-start-event);
}

.bpmn-icon-intermediate-event-none::before {
  color: var(--bpmn-intermediate-throw-event);
}

.bpmn-icon-end-event-none::before {
  color: var(--bpmn-end-event);
}

.bpmn-icon-gateway-none::before {
  color: var(--bpmn-exclusive-gateway);
}

.bpmn-icon-task::before {
  color: var(--bpmn-task);
}

.bpmn-icon-subprocess-expanded::before {
  color: var(--bpmn-sub-process);
}

.bpmn-icon-data-object::before {
  color: var(--bpmn-data-object-reference);
}

.bpmn-icon-data-store::before {
  color: var(--bpmn-data-store-reference);
}

.bpmn-icon-participant::before {
  color: var(--bpmn-participant);
}

.bpmn-icon-group::before {
  color: var(--bpmn-group);
}
